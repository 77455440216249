body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

header {
  position: fixed !important;
}

.gallery-img-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.gallery-img {
  padding: 10px;
}

.create-for-upload {
  color: rgba(0, 0, 0, 0.54);
  font-style: italic;
  font-size: 0.8em
}

.simple-form .form-toolbar button {
  margin: 20px 0px;
}

.attachment-list-item p {
  font-size: 12px;
  padding-right: 48px;
}

.limit-upload {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

.aor-rich-text-input {
  border: 1px solid #ccc
 }
 
 .aor-rich-text-input .ql-editor {
  min-height: 100px;
 }
 
 .aor-rich-text-input .ql-toolbar {
  border-bottom: 1px solid #ccc !important;
  padding: 0 5px 5px 5px !important;
 }

 .gallery-responsive{
   width: 100%;
   height: auto;
 }

 .aor-rich-text-input .ql-editor::after{
   background-color: #313130 !important;
 }

 .remove_underline ul li{
   border-bottom: 0;
   margin-bottom: 30px
 }


 .remove_underline > ul > li:last-child{
   margin-bottom: 30px;
 }


 .features_array > ul > div > li > section{
   padding-right: 15px;
 }


.ra-rich-text-input {
  border: 1px solid black !important;
}

.ql-editor {
  min-height: 200px;
  padding: 0 8px 6px 8px !important;
  height: auto !important;
}

.ql-container {
  height: auto !important;
}

.ra-rich-text-input .ql-toolbar {
  border-bottom: 1px solid black !important;
  padding: 0 6px 6px 6px !important;
}

.m-10{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.box-features-line {
  background-color: #fafafa;
  padding: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
  border: none !important;
}

.box-headers-title{
  margin-left: 10px;
  margin-bottom: 5px;
}
.box-headers{
  background-color: #efefef;
  padding: 5px;
  margin-bottom: 20px;
}
.box-headers-cols{
  padding: 20px;
}

.separator{
  border-top: 1px solid  rgba(0, 0, 0, 0.54);
  height: 1px;
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
